/* eslint-disable prettier/prettier */
import { AxiosRequestConfig, AxiosError } from 'axios'
import { BaseApi } from './base'
import { ActionTypes } from '@/store/auth/actions'
import type {
  studioTypes,
  userTypes,
  clientTypes,
  subcontractorTypes,
  projectTypes,
  documentTypes,
  serviceTypes
} from '@shared/'
import { store } from '@/store'
import router from '@/router'
let errorRequestCounter = 0 // Counter to prevent looping of request when 401 error
class AuthApi extends BaseApi {
  public constructor() {
    super(process.env.VUE_APP_API_URL || '', {})
    this.instance.interceptors.response.use(this._handleResponse, this._handleError)
    this.instance.interceptors.request.use(this._handleRequest)
  }

  public getStudioDetails = (id?: number) => {
    const options = id ? { params: { studioId: id } } : {}
    return this.instance.get<studioTypes.StudioResponse>(`/studio`, options)
  }

  public getAllStudios = () => this.instance.get<studioTypes.StudioWithDetails[]>('/studio/all-studios')

  public updateStudio = (registerBody: studioTypes.StudioPutBody) =>
    this.instance.put<studioTypes.StudioWithDetails>('/studio', registerBody)

  public deleteStudio = (id: number) => this.instance.delete(`/studio/${id}`)

  public getCountries = () => this.instance.get<userTypes.UserResponse[]>('/country')

  // Users

  public getUsers = (id?: number) =>
    this.instance.get<userTypes.UserResponse[]>('/user', id ? { params: { studioId: id } } : {})

  public getUser = (id: number) => this.instance.get<userTypes.UserResponse>('/user/' + id)
  public addNewUser = (registerBody: userTypes.UserBody) =>
    this.instance.post<userTypes.UserResponse[]>('/user', registerBody)

  public updateUser = (registerBody: userTypes.UserBody, id: number) =>
    this.instance.put<userTypes.UserResponse[]>(`/user/${id}`, registerBody)

  public deleteUser = (id: number) => this.instance.delete<userTypes.UserResponse[]>('/user/' + id)

  // CLients

  public getClients = (studioId?: number) =>
    this.instance.get<clientTypes.ClientResponse[]>('/client', studioId ? { params: { studioId: studioId } } : {})

  public getClientDetails = (id: number) => this.instance.get<clientTypes.ClientResponse>(`/client/clientDetails/${id}`)

  public addNewClient = (registerBody: clientTypes.ClientBody, id?: number) =>
    this.instance.post<clientTypes.ClientResponse[]>('/client', registerBody, id ? { params: { studioId: id } } : {})

  public updateClient = (registerBody: clientTypes.ClientBody, id: number) =>
    this.instance.put<clientTypes.ClientResponse[]>(`/client/${id}`, registerBody)

  public deleteClient = (id: number) => this.instance.delete<clientTypes.ClientResponse[]>('/client/' + id)
  public archiveUnarchiveClient = (id: number) =>
    this.instance.put<clientTypes.ClientResponse[]>('/client/archiveUnarchive/' + id)

  public getClientProjects = (id: number) => this.instance.get<projectTypes.ProjectResponse[]>(`/client/${id}/project`)

  // Subcontractors

  public getSubcontractors = (studioId?: number) =>
    this.instance.get<subcontractorTypes.SubcontractorResponse[]>(
      '/subcontractor',
      studioId ? { params: { studioId: studioId } } : {}
    )

  public getSubcontractor = (id: number) =>
    this.instance.get<subcontractorTypes.SubcontractorResponse>('/subcontractor/' + id)

  public addNewSubcontactor = (registerBody: subcontractorTypes.SubcontractorBody) =>
    this.instance.post<subcontractorTypes.SubcontractorResponse[]>('/subcontractor', registerBody)

  public updateSubcontactor = (registerBody: subcontractorTypes.SubcontractorBody, id: number) =>
    this.instance.put<subcontractorTypes.SubcontractorResponse[]>(`/subcontractor/${id}`, registerBody)

  public deleteSubcontactor = (id: number) =>
    this.instance.delete<subcontractorTypes.SubcontractorResponse[]>('/subcontractor/' + id)

  // Project Roles

  public getProjectRoles = (studioId?: number) =>
    this.instance.get<serviceTypes.ServiceResponse[]>('/service', studioId ? { params: { studioId: studioId } } : {})

  public getProjectRole = (id: number) => this.instance.get<serviceTypes.ServiceResponse>('/service/' + id)

  // public addNewProjectRole = (registerBody: serviceTypes.ServiceBody[]) =>
  public addNewProjectRole = (registerBody: any) =>
    this.instance.post<serviceTypes.ServiceResponse[]>('/service', registerBody)

  public updateProjectRole = (registerBody: serviceTypes.ServiceBody, id: number) =>
    this.instance.put<serviceTypes.ServiceResponse[]>(`/service/${id}`, registerBody)

  // public deleteProjectRole = (id: number) =>
  //   this.instance.delete<serviceTypes.ServiceResponse[]>('/service/' + id)

  // Projects

  public getProjects = (studioId?: number) =>
    this.instance.get<projectTypes.ProjectResponse[]>('/project', studioId ? { params: { studioId: studioId } } : {})

  public getProjectDetails = (id: number) => this.instance.get<projectTypes.SingleProjectResponse>('/project/' + id)

  public addNewProject = (registerBody: projectTypes.ProjectBody, id?: number) => {
    return this.instance.post<projectTypes.ProjectResponse[]>(
      '/project',
      registerBody,
      id ? { params: { studioId: id } } : {}
    )
  }

  public updateProject = (registerBody: projectTypes.ProjectBody, id: number) =>
    this.instance.put<projectTypes.ProjectResponse[]>(`/project/${id}`, registerBody)

  public getProjectTeam = (id: number) =>
    this.instance.get<projectTypes.ProjectServicesResponse>(`/project/${id}/services`)

  public updateProjectService = (id: number, projectServices: projectTypes.projectService[]) =>
    this.instance.put(`/project/${id}/services`, projectServices)

  public archiveUnarchiveProject = (id: number) =>
    this.instance.put<projectTypes.ProjectResponse[]>('/project/archiveUnarchive/' + id)

  public deleteProject = (id: number) => this.instance.delete(`/project/${id}`)

  // Expenses
  public addProjectExpense = (registerBody: any, id: number) =>
    this.instance.post<projectTypes.ProjectExpenseResponse>(`/project/${id}/expense`, registerBody, {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      }
    })

  public updateProjectExpenseStatus = (
    projectID: number,
    expenseId: number,
    requestBody: projectTypes.ProjectExpenseStatusPutBody
  ) => this.instance.put(`/project/${projectID}/expense/status/${expenseId}`, requestBody)

  public getProjectExpenses = (id: number) =>
    this.instance.get<projectTypes.ProjectExpenseResponse[]>(`/project/${id}/expense`)

  public getExpenseDetails = (projectID: number, expenseId: number) =>
    this.instance.get<projectTypes.ProjectSingleExpenseResponse>(`/project/${projectID}/expense/${expenseId}`)

  public deleteExpense = (projectID: number, expenseId: number) =>
    this.instance.delete(`/project/${projectID}/expense/${expenseId}`)

  // Invoices
  public getProjectInvoices = (id: number, params: projectTypes.ProjectInvoiceSorting) =>
    this.instance.get<projectTypes.ProjectInvoiceResponse>(`/project/${id}/invoice`, { params })

  public getInvoiceDetails = (projectID: number, invoiceId: number) =>
    this.instance.get<projectTypes.ProjectSingleInvoiceResponse>(`/project/${projectID}/invoice/${invoiceId}`)

  public editInvoice = (projectID: number, invoiceId: number, requestBody: projectTypes.ProjectInvoicePutBody) =>
    this.instance.put<projectTypes.ProjectSingleInvoiceResponse>(
      `/project/${projectID}/invoice/${invoiceId}`,
      requestBody
    )

  public deleteInvoice = (projectID: number, invoiceId: number) =>
    this.instance.delete(`/project/${projectID}/invoice/${invoiceId}`)

  public invoicePdf = (projectId: number, invoiceId: number, preview: boolean, send = false) =>
    this.instance.post<Blob>(
      `/project/${projectId}/invoicePdf/${invoiceId}}`,
      { preview, send },
      {
        responseType: 'blob'
      }
    )

  public updateInvoiceStatus = (
    projectID: number,
    invoiceId: number,
    requestBody: projectTypes.ProjectInvoiceStatusPutBody
  ) => this.instance.put(`/project/${projectID}/invoice/status/${invoiceId}`, requestBody)

  public downloadInvoice = (projectID: number, invoiceId: number) =>
    this.instance.get<projectTypes.SingleInvoiceDownloadResponse>(`/project/${projectID}/download/invoice/${invoiceId}`)

  /// ////// Timesheets
  public getWorkLogs = (projectID: number, weekNumber: number) =>
    this.instance.get<projectTypes.ProjectTimeLoggingResponse>(`/project/${projectID}/worklog`, {
      params: { weekNumber: weekNumber }
    })

  public updateWorkLogs = (projectID: number, requestBody: projectTypes.ProjectTimeLoggingPostBody) =>
    this.instance.post(`/project/${projectID}/worklog`, requestBody)

  public deleteWorkLog = (projectID: number, workLogId: number) =>
    this.instance.delete(`/project/${projectID}/worklog/${workLogId}`)

  public getAllWorkLogs = (projectID: number) =>
    this.instance.get<projectTypes.ProjectTimeLoggingResponse>(`/project/${projectID}/worklogs`)

  //  Documents

  public getDocuments = () => this.instance.get<documentTypes.DocumentResponse[]>('/studio/document')
  public deleteDocument = (id: number) =>
    this.instance.delete<documentTypes.DocumentResponse[]>('/studio/document/' + id)

  public addNewDocument = (registerBody: any) =>
    this.instance.post<documentTypes.DocumentResponse[]>('/studio/document', registerBody, {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      }
    })

  public downloadDocument = (id: number) =>
    this.instance.get<documentTypes.SingleDocumentResponse>(`/studio/document/${id}`)

  public addProjectDocument = (projectId: number, registerBody: any) =>
    this.instance.post<documentTypes.ProjectDocumentResponse[]>(`/project/${projectId}/document`, registerBody, {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      }
    })

  public getProjectDocuments = (projectId: number) =>
    this.instance.get<documentTypes.ProjectDocumentResponse>(`/project/${projectId}/document`)

  public deleteProjectDocument = (projectId: number, documentId: number) =>
    this.instance.delete<documentTypes.ProjectDocumentResponse[]>(`/project/${projectId}/document/${documentId}`)

  public downloadProjectDocument = (projectId: number, documentId: number) =>
    this.instance.get<documentTypes.SingleDocumentResponse>(`/project/${projectId}/document/${documentId}`)

  public getClientDocuments = (clientId: number) =>
    this.instance.get<documentTypes.ClientDocumentResponse[]>(`/client/${clientId}/document`)

  public sowPdf = (projectId: number, preview: boolean, send: boolean) =>
    this.instance.post<Blob>(
      `/project/${projectId}/sowPdf`,
      { preview, send },
      {
        responseType: 'blob'
      }
    )

  public addClientDocument = (clientId: number, registerBody: any) =>
    this.instance.post<documentTypes.ClientDocumentResponse[]>(`/client/${clientId}/document`, registerBody, {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      }
    })

  public deleteClientDocument = (clientId: number, documentId: number) =>
    this.instance.delete<documentTypes.ClientDocumentResponse[]>(`/client/${clientId}/document/${documentId}`)

  public downloadClientDocument = (clientId: number, documentId: number) =>
    this.instance.get<documentTypes.SingleDocumentResponse>(`/client/${clientId}/document/${documentId}`)

  public getSubcontractorDocuments = (subcontractorId: number) =>
    this.instance.get<documentTypes.SubcontractorDocumentResponse[]>(`/subcontractor/${subcontractorId}/document`)

  public addSubcontractorDocument = (subcontractorId: number, registerBody: any) =>
    this.instance.post<documentTypes.SubcontractorDocumentResponse[]>(
      `/subcontractor/${subcontractorId}/document`,
      registerBody,
      {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded'
        }
      }
    )

  public deleteSubcontractorDocument = (subcontractorId: number, documentId: number) =>
    this.instance.delete<documentTypes.SubcontractorDocumentResponse[]>(
      `/client/${subcontractorId}/document/${documentId}`
    )

  public downloadSubcontractorDocument = (id: number, documentId: number) =>
    this.instance.get<documentTypes.SingleDocumentResponse>(`/subcontractor/${id}/document/${documentId}`)

  public getProjectDashboardDetail = (id: number) =>
    this.instance.get<projectTypes.ProjectDashboardResponse>(`/project/${id}/dashboard`)

  public getStudioDashboardDetail = () => {
    return this.instance.get<studioTypes.StudioDashboardResponse>('/studio/dashboard')
  }

  public retryFromConfig = (config: AxiosRequestConfig) => {
    errorRequestCounter = errorRequestCounter + 1
    this.instance(config)
  }

  public sendMail = (registerBody: any) => this.instance.post<any>('/auth/sendEmail', registerBody)

  protected _handleRequest = (config: AxiosRequestConfig) => {
    config.headers.Authorization = `Bearer ${store.state.auth.jwt}`
    return config
  }

  // SUPER ADMIN

  public registerAdminStudio = (registerBody: studioTypes.RegisterAdminStudio) =>
    this.instance.post<studioTypes.StudioResponse>('/studio/add', registerBody)

  protected _handleError = async (error: AxiosError) => {
    if (error.response) {
      const { status } = error.response
      switch (status) {
        case 401:
          // check if 401 error was token
          // token has expired;
          try {
            // attempting to refresh token;
            await store.dispatch(ActionTypes.REFRESH_TOKEN)
            // token refreshed, reattempting request;
            const config = error.config
            // configure new request in a new instance;
            if (errorRequestCounter === 0) {
              // check if the request is first time when error is 401 / token expired
              this.retryFromConfig(config)
            }
            return Promise.reject(error)
          } catch (e) {
            return router.push({ name: 'Login' })
          }
        default:
          return Promise.reject(error)
      }
    } else if (error.request) {
      // The request was made but no response was received
      // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
      // http.ClientRequest in node.js
      return Promise.reject(error)
    } else {
      // Something happened in setting up the request that triggered an Error
      return Promise.reject(error)
    }
  }
}

const authApi: AuthApi = new AuthApi()

export default authApi
