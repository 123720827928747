
import { defineComponent, PropType } from 'vue'
export default defineComponent({
  props: {
    links: {
      type: Array as PropType<{ routerName: string; routerText: string; icon: string; menuType: string }[]>,
      default: () => []
    },
    isLoggedIn: Boolean
  },
  name: 'Header'
})
