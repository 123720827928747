import { GetterTree } from 'vuex'
import { State } from './index'
import type { State as RootState } from '@/store'
import type { projectTypes } from '@shared/'
export type Getters<S = State> = {
  activeProjects(state: S): projectTypes.ProjectResponse[]
  archiveProjects(state: S): projectTypes.ProjectResponse[]
}

export const getters: GetterTree<State, RootState> & Getters = {
  activeProjects: (state) => {
    return state.projects.filter((e) => e.isArchived !== true)
  },
  archiveProjects: (state) => {
    return state.projects.filter((e) => e.isArchived === true)
  }
}
