/* eslint-disable prettier/prettier */
import { ActionContext, ActionTree } from 'vuex'
import { Mutations, MutationTypes } from './mutations'
import { State } from './index'
import type { State as RootState } from '@/store'
import authApi from '@/api/Authenticated'

export enum ActionTypes {
  GET_PROJECTS = 'GET_PROJECTS',
  GET_CLIENT_PROJECTS = 'GET_CLIENT_PROJECTS',
  GET_PROJECT_DETAILS = 'GET_PROJECT_DETAILS',
  GET_PROJECT_TEAM = 'GET_PROJECT_TEAM'
}


type AugmentedActionContext = {
  commit<K extends keyof Mutations>(key: K, payload: Parameters<Mutations[K]>[1]): ReturnType<Mutations[K]>
} & Omit<ActionContext<State, RootState>, 'commit'>

export interface Actions {
  [ActionTypes.GET_PROJECTS]({ commit }: AugmentedActionContext, studioId: number): void
  [ActionTypes.GET_CLIENT_PROJECTS]({ commit }: AugmentedActionContext, clientId: number): void
  [ActionTypes.GET_PROJECT_DETAILS]({ commit }: AugmentedActionContext, id: number): void
  [ActionTypes.GET_PROJECT_TEAM]({ commit }: AugmentedActionContext, id: number): void
}

export const actions: ActionTree<State, RootState> & Actions = {
  async [ActionTypes.GET_PROJECTS]({ commit }, studioId?) {
    const res = await authApi.getProjects(studioId)
    commit(MutationTypes.SET_PROJECTS, res)
  },
  async [ActionTypes.GET_CLIENT_PROJECTS]({ commit }, clientId?) {
    const res = await authApi.getClientProjects(+clientId)
    commit(MutationTypes.SET_PROJECTS, res)
  },
  async [ActionTypes.GET_PROJECT_DETAILS]({ commit }, id) {
    const res = await authApi.getProjectDetails(id)
    commit(MutationTypes.SET_PROJECT_DETAILS, res)
  },
  async [ActionTypes.GET_PROJECT_TEAM]({ commit }, id) {
    commit(MutationTypes.SET_PROJECT_TEAM, null)
    const res = await authApi.getProjectTeam(id)
    commit(MutationTypes.SET_PROJECT_TEAM, res)
  }
}
