
import { defineComponent, computed, ref } from 'vue'
import { createToast } from 'mosha-vue-toastify'
import 'mosha-vue-toastify/dist/style.css'

export default defineComponent({
  props: {
    showToast: Boolean,
    message: String,
    type: String,
    color: String
  },

  setup(props) {
    const data = props
    const toasterColor = ref('green')
    const toastType: any[] = []
    if (data.type !== 'success') {
      toasterColor.value = 'red'
    }
    toastType.push({
      toastBackgroundColor: toasterColor.value,
      position: 'top-right',
      type: data.type,
      transition: 'zoom',
      showIcon: true,
      hideProgressBar: true,
      timeout: 1500
    })
    const toast = computed(() => createToast(data.message ? data.message : '', toastType[0]))
    return { toast }
  }
})
