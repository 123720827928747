/* eslint-disable prettier/prettier */
import { ActionContext, ActionTree } from 'vuex'
import { Mutations, MutationTypes } from './mutations'
import { State } from './index'
import type { State as RootState } from '@/store'
import authApi from '@/api/Authenticated'

export enum ActionTypes {
  GET_CLIENTS = 'GET_CLIENTS',
  GET_CLIENT_DETAILS = 'GET_CLIENT_DETAILS'
}

type AugmentedActionContext = {
  commit<K extends keyof Mutations>(key: K, payload: Parameters<Mutations[K]>[1]): ReturnType<Mutations[K]>
} & Omit<ActionContext<State, RootState>, 'commit'>

export interface Actions {
  [ActionTypes.GET_CLIENTS]({ commit }: AugmentedActionContext, studioId: number): void
  [ActionTypes.GET_CLIENT_DETAILS]({ commit }: AugmentedActionContext, id: number): void
}

export const actions: ActionTree<State, RootState> & Actions = {
  async [ActionTypes.GET_CLIENTS]({ commit }, studioId) {
    const res = await authApi.getClients(studioId)
    commit(MutationTypes.SET_CLIENTS, res)
  },
  async [ActionTypes.GET_CLIENT_DETAILS]({ commit }, id) {
    const res = await authApi.getClientDetails(id)
    commit(MutationTypes.SET_CLIENT_DETAILS, res)
  }
}
