import { ActionContext, ActionTree } from 'vuex'
import { Mutations, MutationTypes } from './mutations'
import { State } from './index'
import type { State as RootState } from '@/store'
import router from '@/router'
import noAuthApi from '@/api/Unauthenticated'
import type { authTypes } from '@shared/'
import type { TokenContentClient } from './types'
import authApi from '@/api/Authenticated'

const parseJwt = (token: string): TokenContentClient => {
  try {
    return JSON.parse(atob(token.split('.')[1]))
  } catch (e) {
    return null
  }
}

export enum ActionTypes {
  LOGIN = 'LOGIN',
  LOGOUT = 'LOGOUT',
  REFRESH_TOKEN = 'REFRESH_TOKEN',
  REGISTER = 'REGISTER',
  GET_STUDIO_DETAILS = 'GET_STUDIO_DETAILS'
}

type AugmentedActionContext = {
  commit<K extends keyof Mutations>(key: K, payload: Parameters<Mutations[K]>[1]): ReturnType<Mutations[K]>
} & Omit<ActionContext<State, RootState>, 'commit'>

export interface Actions {
  [ActionTypes.LOGIN]({ commit }: AugmentedActionContext, loginCredentials: authTypes.AuthBody): void
  [ActionTypes.LOGOUT]({ commit }: AugmentedActionContext): void
  [ActionTypes.REFRESH_TOKEN]({ commit }: AugmentedActionContext): void
  [ActionTypes.REGISTER]({ commit }: AugmentedActionContext, registerBody: authTypes.RegisterBody): void
  [ActionTypes.GET_STUDIO_DETAILS]({ commit }: AugmentedActionContext): void
}

export const actions: ActionTree<State, RootState> & Actions = {
  async [ActionTypes.LOGIN]({ commit }, loginCredentials) {
    const res = await noAuthApi.login(loginCredentials)
    if (res) {
      commit(MutationTypes.SET_LOGIN, res.token)
      commit(MutationTypes.SET_TOKEN_CONTENT, parseJwt(res.token))
      router.push({ name: 'Studio' })
    }
  },
  async [ActionTypes.LOGOUT]({ commit }) {
    await noAuthApi.logout()
    commit(MutationTypes.SET_LOGIN, '')
    commit(MutationTypes.SET_TOKEN_CONTENT, null)
    router.push({ name: 'Login' })
  },
  async [ActionTypes.REFRESH_TOKEN]({ commit }) {
    const res = await noAuthApi.refreshToken()
    if (res) {
      commit(MutationTypes.SET_LOGIN, res.token)
      commit(MutationTypes.SET_TOKEN_CONTENT, parseJwt(res.token))
    }
  },
  async [ActionTypes.REGISTER]({ commit }, registerBody) {
    const res = await noAuthApi.register(registerBody)
    if (res) {
      // commit(MutationTypes.SET_LOGIN, res.token)
      // commit(MutationTypes.SET_TOKEN_CONTENT, parseJwt(res.token))
      router.push({ name: 'VerificationMessage', params: { studioName: registerBody.studioName } })
    }
  },
  async [ActionTypes.GET_STUDIO_DETAILS]({ commit }) {
    const res = await authApi.getStudioDetails()
    if (res) {
      commit(MutationTypes.SET_STUDIO_DETAILS, res)
    }
  }
}
