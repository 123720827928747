import { MutationTree } from 'vuex'
import { State } from './index'
import type { studioTypes, subcontractorTypes } from '@shared/'

export enum MutationTypes {
  SET_SUBCONTRACTORS = 'SET_SUBCONTRACTORS',
  SET_SUBCONTRACTOR_DETAILS = 'SET_SUBCONTRACTOR_DETAILS',
  DELETE_SUBCONTRACTOR = 'DELETE_SUBCONTRACTOR',
  SET_STUDIO_DETAILS = 'SET_STUDIO_DETAILS'
}

export type Mutations<S = State> = {
  [MutationTypes.SET_SUBCONTRACTORS](state: S, subcontractors: subcontractorTypes.SubcontractorResponse[]): void
  [MutationTypes.SET_SUBCONTRACTOR_DETAILS](
    state: S,
    subcontractorDetails: subcontractorTypes.SubcontractorResponse
  ): void
  [MutationTypes.DELETE_SUBCONTRACTOR](state: S, subcontractorId: number): void
  [MutationTypes.SET_STUDIO_DETAILS](state: S, details: studioTypes.StudioWithDetails): void
}

export const mutations: MutationTree<State> & Mutations = {
  [MutationTypes.SET_SUBCONTRACTORS](state, subcontractors) {
    state.subContractors = subcontractors
  },
  [MutationTypes.SET_SUBCONTRACTOR_DETAILS](state, subcontractorDetails) {
    state.SubcontractorDetails = subcontractorDetails
  },
  [MutationTypes.DELETE_SUBCONTRACTOR](state, subcontractorId) {
    const subcontractorIndex = state.subContractors.findIndex((e: any) => e.id === subcontractorId)
    state.subContractors.splice(subcontractorIndex, 1)
  },
  [MutationTypes.SET_STUDIO_DETAILS](state, details) {
    state.studioDetails = details
  }
}
