import { InjectionKey } from 'vue'
import { createStore, useStore as baseUseStore, Store } from 'vuex'
import auth, { State as AuthState, Store as AuthStore } from './auth'
import user, { State as UserState, Store as UserStore } from './users'
import client, { State as ClientState, Store as ClientStore } from './clients'
import studio, { State as studioState, Store as StudioStore } from './studio'
import project, { State as ProjectState, Store as ProjectStore } from './projects'
import messages, { State as MessageState, Store as MessageStore } from './messages'

export interface State {
  auth: AuthState
  user: UserState
  client: ClientState
  studio: studioState
  project: ProjectState
  messages: MessageState
}

export type RootStore = AuthStore<Pick<State, 'auth'>> &
  UserStore<Pick<State, 'user'>> &
  ClientStore<Pick<State, 'client'>> &
  StudioStore<Pick<State, 'studio'>> &
  ProjectStore<Pick<State, 'project'>> &
  MessageStore<Pick<State, 'messages'>>

export const key: InjectionKey<Store<State>> = Symbol('store')

export const store = createStore<State>({
  modules: {
    auth,
    user,
    client,
    studio,
    project,
    messages
  }
})

export function useStore() {
  return baseUseStore(key)
}
