<template>
  <Header @logout="logout" :isLoggedIn="isLoggedIn" />
  <div v-if="isLoggedIn" class="main">
    <Navigation :links="links" />
    <div class="page-wrap" v-if="studioDetails">
      <router-view />
    </div>
  </div>
  <div v-else>
    <router-view />
  </div>
</template>

<script lang="ts">
import { defineComponent, computed } from 'vue'
import { useStore } from '@/store'
import Header from '@/components/organisms/Header.vue'
import Navigation from '@/components/organisms/Navigation.vue'
import { ActionTypes as AuthActionTypes } from '@/store/auth/actions'
import { ActionTypes as studioActions } from '@/store/studio/actions'

export default defineComponent({
  name: 'App',
  components: {
    Header,
    Navigation
  },
  setup() {
    const store = useStore()
    store.dispatch(studioActions.GET_STUDIO_DETAILS_BY_ID)
    const isLoggedIn = computed(() => store.state.auth.jwt.length > 0)
    const studioDetails = computed(() => store.state.studio.studioDetails)
    const logout = () => {
      store.dispatch(AuthActionTypes.LOGOUT)
    }
    const links = [
      {
        routerName: 'Studio',
        routerText: 'My Studio',
        icon: 'Placeholder--active.svg',
        menuType: 'studio'
      },
      { routerName: 'Tasks', routerText: 'Tasks', icon: 'Tasks.svg', menuType: 'tasks' },
      { routerName: 'Clients', routerText: 'Clients', icon: 'Clients.svg', menuType: 'clients' },
      { routerName: 'Projects', routerText: 'Projects', icon: 'Projects.svg', menuType: 'projects' },
      { routerName: 'BooksDashboard', routerText: 'Books', icon: 'Placeholder.svg', menuType: 'books' }
    ]
    return { isLoggedIn, studioDetails, links, logout }
  }
})
</script>

<style lang="sass">
.main
  display: flex
  flex: 1
  // overflow: auto
  @media( max-width: $md-breakpoint)
    flex-direction: column
.page-wrap
  flex: 1
  display: flex
  flex-direction: column
</style>
