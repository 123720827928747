<template>
  <header :class="headerClass">
    <div class="logo-container">
      <img
        class="logo"
        :src="!isAdmin ? require('@/assets/images/Studiospace.svg') : require('@/assets/images/StudiospaceAdmin.svg')"
      />
      <CustomButton v-if="isAdmin" label="Super admin" color="darkGrey" size="medium" :iconVisible="false" />
    </div>
    <div v-if="isLoggedIn" v-click-away="onClickAway" @click="open = !open" class="dropdown">
      <span> {{ userName }} </span>
      <img class="isDesktop" src="@/assets/icons/arrow-down-white.svg" alt="" />
      <ul class="dropdown__menu" :class="{ show: open }">
        <li v-if="userId" @click="$router.push({ name: 'EditTeam', params: { id: userId } })">Profile</li>
        <li @click="logout">Logout</li>
      </ul>
    </div>
  </header>
</template>

<script lang="ts">
import { ref, defineComponent, computed } from 'vue'
import { useRoute } from 'vue-router'
import { useStore } from '@/store'
import { directive } from 'vue3-click-away'
import CustomButton from '@/components/atom/CustomButton.vue'

export default defineComponent({
  props: {
    isLoggedIn: Boolean,
    headerClass: String
  },
  components: {
    CustomButton
  },
  directives: {
    ClickAway: directive
  },
  name: 'Header',
  setup(props, { emit }) {
    const route = useRoute()
    const isAdmin = computed(() => route.path.indexOf('super-admin') > -1)
    const store = useStore()
    const open = ref(false)
    const userName = computed(() =>
      store.state.auth.tokenContent ? store.state.auth.tokenContent.userName : 'User name'
    )
    const userId = computed(() => {
      return store.state.auth.tokenContent ? store.state.auth.tokenContent.userId : null
    })
    function onClickAway() {
      open.value = false
    }

    const logout = () => {
      emit('logout')
    }
    return { logout, open, userName, onClickAway, userId, isAdmin }
  }
})
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="sass">
.transparent
  background-color: transparent
  box-shadow: none
header
  box-shadow: inset 0px -2px 0px rgba(0, 0, 0, 0.05)
  flex: none
  background: #FFF
  max-width: 100%
  padding: 0 1.75rem
  height: 4.5em
  display: flex
  justify-content: space-between
  align-items: center
  width: 100%
  z-index: 11
  &.superAdmin
    background: $primary
    span
      color: $white
  .logo
    height: 3.875rem

  .right
    display: flex
    align-items: center

  .dropdown
    margin-right: 1rem
    font-size: 0.875rem
    position: relative
    cursor: pointer
    display: flex
    align-items: center
    > span
      padding-right: 0.25rem
    &__menu
      display: none
      position: absolute
      top: 1rem
      background: $white
      width: 100%
      padding: 0
      list-style: none
      box-shadow: 0px 2px 20px rgba(0,0 ,0 ,0.1)
      left: -1rem
      &.show
        display: block
        min-width: 5rem
      li
        padding: 0.2rem 1rem
        border-bottom: solid 1px #eee
  @media( max-width: $md-breakpoint)
    height: 2.5em
    box-shadow: none
    align-items: flex-start
    justify-content: center
    .logo
      height: 2.875rem
      margin-top: 0.0625rem
    .dropdown
      display: none
.logo-container
  display: flex
  align-items: center
.superAdmin
  .darkGrey
    margin-left: 26px
    width: 110px
</style>
