
export default {
  name: 'Tooltip',
  props: {
    tooltipTitle: {
      type: String,
      default: ''
    },
    tooltipText: {
      type: String,
      default: ''
    },
    position: {
      default: 'right',
      type: String
    },
    fieldType: {
      type: String,
      default: ''
    }
  },
  setup() {
    const checkMediaQuery = (fieldType: string) => {
      if (window.innerWidth <= 768) {
        return fieldType === 'input'
          ? 'top'
          : fieldType === 'dashboard' || fieldType === 'growth'
          ? 'left'
          : fieldType === 'timesheet'
          ? 'top'
          : 'bottom'
      }
      return fieldType === 'growth' ? 'left' : fieldType === 'timesheet' ? 'top' : 'right'
    }
    return { checkMediaQuery }
  }
}
