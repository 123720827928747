
import { ref, defineComponent, computed } from 'vue'
import { useRoute } from 'vue-router'
import { useStore } from '@/store'
import { directive } from 'vue3-click-away'
import CustomButton from '@/components/atom/CustomButton.vue'

export default defineComponent({
  props: {
    isLoggedIn: Boolean,
    headerClass: String
  },
  components: {
    CustomButton
  },
  directives: {
    ClickAway: directive
  },
  name: 'Header',
  setup(props, { emit }) {
    const route = useRoute()
    const isAdmin = computed(() => route.path.indexOf('super-admin') > -1)
    const store = useStore()
    const open = ref(false)
    const userName = computed(() =>
      store.state.auth.tokenContent ? store.state.auth.tokenContent.userName : 'User name'
    )
    const userId = computed(() => {
      return store.state.auth.tokenContent ? store.state.auth.tokenContent.userId : null
    })
    function onClickAway() {
      open.value = false
    }

    const logout = () => {
      emit('logout')
    }
    return { logout, open, userName, onClickAway, userId, isAdmin }
  }
})
