import { GetterTree } from 'vuex'
import { State } from './index'
import type { State as RootState } from '@/store'

export type Getters<S = State> = {
  isLoggedIn(state: S): boolean
}

export const getters: GetterTree<State, RootState> & Getters = {
  isLoggedIn: (state) => {
    return state.jwt.length > 0
  }
}
