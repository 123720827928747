import { ActionContext, ActionTree } from 'vuex'
import { Mutations, MutationTypes } from './mutations'
import { State } from './index'
import type { State as RootState } from '@/store'
import authApi from '@/api/Authenticated'

export enum ActionTypes {
  GET_SUBCONTRACTORS = 'GET_SUBCONTRACTORS',
  GET_SUBCONTRACTOR_DETAILS = 'GET_SUBCONTRACTOR_DETAILS',
  GET_STUDIO_DETAILS_BY_ID = 'GET_STUDIO_DETAILS_BY_ID'
}

type AugmentedActionContext = {
  commit<K extends keyof Mutations>(key: K, payload: Parameters<Mutations[K]>[1]): ReturnType<Mutations[K]>
} & Omit<ActionContext<State, RootState>, 'commit'>

export interface Actions {
  [ActionTypes.GET_SUBCONTRACTORS]({ commit }: AugmentedActionContext, studioId?: number): void
  [ActionTypes.GET_SUBCONTRACTOR_DETAILS]({ commit }: AugmentedActionContext, id: number): void
  [ActionTypes.GET_STUDIO_DETAILS_BY_ID]({ commit }: AugmentedActionContext, id?: number): void
}

export const actions: ActionTree<State, RootState> & Actions = {
  async [ActionTypes.GET_SUBCONTRACTORS]({ commit }, studioId) {
    const res = await authApi.getSubcontractors(studioId)
    commit(MutationTypes.SET_SUBCONTRACTORS, res)
  },
  async [ActionTypes.GET_SUBCONTRACTOR_DETAILS]({ commit }, id) {
    const res = await authApi.getSubcontractor(id)
    commit(MutationTypes.SET_SUBCONTRACTOR_DETAILS, res)
  },
  async [ActionTypes.GET_STUDIO_DETAILS_BY_ID]({ commit }, id?) {
    const res = await authApi.getStudioDetails(id)
    commit(MutationTypes.SET_STUDIO_DETAILS, res)
  }
}
