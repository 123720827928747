import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import Studio from '../components/templates/Studio.vue'
import Wrapper from '../components/templates/DefaultView.vue'
import AsyncWrapper from '../components/templates/AsyncWrapper.vue'
import { store } from '@/store'
import { ActionTypes } from '@/store/auth/actions'
import { trackRouter } from 'vue-gtag-next'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'Wrapper',
    component: Wrapper,
    redirect: {
      name: 'Studio'
    },
    meta: {
      requiresAuth: true
    },
    children: [
      {
        path: '/studio',
        name: 'Studio',
        component: Studio,
        redirect: {
          name: 'Studio-Dashboard'
        },
        meta: {
          requiresAuth: true,
          menuType: 'studio'
        },
        children: [
          {
            name: 'Studio-Dashboard',
            path: '',
            component: () => import(/* webpackChunkName: "Dashboard" */ '@/components/pages/studio/Dashboard.vue')
          },
          {
            name: 'Details',
            path: 'details',
            component: () => import(/* webpackChunkName: "Details" */ '@/components/pages/studio/Details.vue')
          },
          {
            name: 'Roles',
            path: 'roles',
            component: () => import(/* webpackChunkName: "servcies" */ '@/components/pages/studio/services.vue')
          },
          {
            name: 'Team',
            path: 'team',
            component: () => import(/* webpackChunkName: "Team" */ '@/components/pages/studio/Team.vue')
          },
          {
            name: 'Subcontractors',
            path: 'subcontractors',
            component: () =>
              import(/* webpackChunkName: "Subcontractors" */ '@/components/pages/studio/Subcontractors.vue')
          },
          {
            name: 'Documents',
            path: 'documents',
            component: () => import(/* webpackChunkName: "Documents" */ '@/components/pages/studio/Documents.vue')
          }
        ]
      },
      {
        name: 'SubcontractorDetails',
        path: 'studio/subcontractors/:id',
        meta: {
          requiresAuth: true,
          menuType: 'studio'
        },
        component: () =>
          import(/* webpackChunkName: "ClientDetails" */ '@/components/pages/studio/SubcontractorDetails.vue'),
        children: [
          {
            name: 'SubcontractorProjects',
            path: 'projects',
            component: () =>
              import(
                /* webpackChunkName: "SubcontractorProjects" */ '@/components/pages/studio/SubcontractorProjects.vue'
              )
          },
          {
            alias: '',
            name: 'SubcontractorInformation',
            path: 'details',
            component: () =>
              import(
                /* webpackChunkName: "SubcontractorInformation" */ '@/components/pages/studio/SubcontractorInformation.vue'
              )
          },
          {
            name: 'SubcontractorDocuments',
            path: 'documents',
            component: () =>
              import(
                /* webpackChunkName: "SubcontractorDocuments" */ '@/components/pages/studio/SubcontractorDocuments.vue'
              )
          }
        ]
      },
      {
        path: 'tasks',
        name: 'Tasks',
        component: () => import(/* webpackChunkName: "Tasks" */ '../components/pages/Tasks.vue')
      },
      {
        path: '/clients',
        name: 'Clients',
        component: () => import(/* webpackChunkName: "Clients" */ '../components/pages/clients/Clients.vue'),
        redirect: {
          name: 'ClientsListings'
        },
        children: [
          {
            name: 'ClientsListings',
            path: 'active',
            component: () =>
              import(/* webpackChunkName: "ClientOverview" */ '@/components/pages/clients/ClientListing.vue')
          },
          {
            name: 'ArchiveClients',
            path: 'archived',
            component: () =>
              import(/* webpackChunkName: "ClientOverview" */ '@/components/pages/clients/ClientListing.vue')
          }
        ]
      },
      {
        name: 'ClientDetails',
        path: '/clients/:id',
        meta: {
          requiresAuth: true,
          menuType: 'clients'
        },
        component: () => import(/* webpackChunkName: "ClientDetails" */ '@/components/pages/clients/ClientDetails.vue'),
        children: [
          {
            path: '',
            component: AsyncWrapper,
            children: [
              {
                name: 'ClientProjects',
                path: 'projects',
                component: () =>
                  import(/* webpackChunkName: "ClientProjects" */ '@/components/pages/clients/ClientProjects.vue')
              },
              {
                alias: '',
                name: 'ClientInformation',
                path: 'details',
                component: () =>
                  import(/* webpackChunkName: "ClientInformation" */ '@/components/pages/clients/ClientInformation.vue')
              },
              {
                name: 'ClientDocuments',
                path: 'documents',
                component: () =>
                  import(/* webpackChunkName: "ClientDocuments" */ '@/components/pages/clients/ClientDocuments.vue')
              }
            ]
          }
        ]
      },
      {
        path: '/projects',
        name: 'Projects',
        component: () => import(/* webpackChunkName: "Projects" */ '../components/pages/project/Projects.vue'),
        meta: {
          requiresAuth: true,
          menuType: 'projects'
        },
        redirect: {
          name: 'ProjectListing'
        },
        children: [
          {
            path: 'active',
            name: 'ProjectListing',
            component: () => import(/* webpackChunkName: "Projects" */ '../components/organisms/ProjectList.vue')
          },
          {
            path: 'archived',
            name: 'ArchiveProjects',
            component: () =>
              import(/* webpackChunkName: "Projects" */ '../components/pages/project/ProjectsListing.vue')
          }
        ]
      },
      {
        path: '/projects/:id',
        name: 'ProjectDetails',
        component: () =>
          import(/* webpackChunkName: "ProjectDetailsLayout" */ '../components/pages/project/ProjectDetailsLayout.vue'),
        redirect: {
          name: 'ProjectDashboard'
        },
        meta: {
          menuType: 'projects'
        },
        children: [
          // {
          {
            path: '',
            name: 'ProjectDashboard',
            component: () =>
              import(/* webpackChunkName: "ProjectDashboard" */ '../components/pages/project/ProjectDashboard.vue')
          },
          {
            path: 'invoicing',
            name: 'ProjectInvoicing',
            component: () =>
              import(/* webpackChunkName: "ProjectInvoicing" */ '../components/pages/project/ProjectInvoicing.vue')
          },
          {
            path: 'expenses',
            name: 'ProjectExpenses',
            component: () =>
              import(/* webpackChunkName: "ProjectExpenses" */ '../components/pages/project/ProjectExpenses.vue')
          },
          {
            path: 'team',
            name: 'ProjectTeam',
            component: () => import(/* webpackChunkName: "ProjectTeam" */ '../components/pages/project/ProjectTeam.vue')
          },
          {
            path: 'documents',
            name: 'ProjectDocuments',
            component: () =>
              import(/* webpackChunkName: "ProjectDocuments" */ '../components/pages/project/ProjectDocuments.vue')
          },
          {
            path: 'details',
            name: 'ProjectDetail',
            component: () =>
              import(/* webpackChunkName: "ProjectDetails" */ '../components/pages/project/ProjectDetails.vue')
          },
          {
            path: 'timesheets',
            name: 'ProjectTimesheets',
            component: () =>
              import(/* webpackChunkName: "ProjectDetails" */ '../components/pages/project/ProjectTimesheets.vue')
          }
        ]
      },

      {
        path: '/books',
        name: 'Books',
        component: () => import(/* webpackChunkName: "Books" */ '../components/pages/book/Books.vue'),
        meta: {
          requiresAuth: true
        },
        redirect: {
          name: 'Dashboard'
        },
        children: [
          {
            path: 'dashboard',
            name: 'BooksDashboard',
            component: () => import('../components/pages/book/Dashboard.vue')
          },
          {
            path: 'invoicing',
            name: 'Invoicing',
            component: () => import('../components/pages/book/Invoicing.vue')
          },
          {
            path: 'expenses',
            name: 'Expenses',
            component: () => import('../components/pages/book/Expenses.vue')
          },
          {
            path: 'finance',
            name: 'Finance',
            component: () => import('../components/pages/book/Finance.vue')
          }
        ]
      }
    ]
  },
  {
    path: '/',
    component: AsyncWrapper,
    meta: {
      requiresAuth: true
    },
    children: [
      {
        name: 'InvoiceDetail',
        path: 'projects/:projectId/invoice/:invoiceId',
        component: () => import(/* webpackChunkName: "AddTeamMembers" */ '@/components/pages/invoice/InvoiceDetail.vue')
      },
      {
        name: 'AddDocument',
        path: 'studio/documents/new',
        component: () => import(/* webpackChunkName: "addDocument" */ '@/components/pages/studio/AddDocument.vue')
      },
      {
        name: 'AddClient',
        path: 'clients/new',
        component: () => import(/* webpackChunkName: "Client" */ '@/components/pages/clients/Client.vue')
      },
      {
        name: 'EditClient',
        path: 'clients/:id/edit',
        component: () => import(/* webpackChunkName: "Client" */ '@/components/pages/clients/Client.vue')
      },
      {
        name: 'AddSubcontractor',
        path: 'studio/subcontractors/new',
        component: () => import(/* webpackChunkName: "Subcontractor" */ '@/components/pages/studio/Subcontractor.vue')
      },
      {
        name: 'EditSubcontractor',
        path: 'studio/subcontractors/:id/edit',
        component: () => import(/* webpackChunkName: "Subcontractor" */ '@/components/pages/studio/Subcontractor.vue')
      },
      {
        name: 'AddTeam',
        path: 'studio/team/new',
        component: () => import(/* webpackChunkName: "AddTeam" */ '@/components/pages/studio/TeamMembers.vue')
      },
      {
        name: 'EditTeam',
        path: 'studio/team/edit',
        component: () => import(/* webpackChunkName: "AddTeam" */ '@/components/pages/studio/TeamMembers.vue')
      },
      {
        name: 'AddProject',
        path: 'projects/new',
        component: () => import(/* webpackChunkName: "AddTeam" */ '@/components/pages/project/EditProject.vue')
      },
      {
        name: 'EditProject',
        path: 'projects/:id/edit',
        component: () => import(/* webpackChunkName: "AddTeam" */ '@/components/pages/project/EditProject.vue')
      },
      {
        name: 'AddProjectDocument',
        path: 'projects/:id/documents/new',
        component: () => import(/* webpackChunkName: "AddTeamMembers" */ '@/components/pages/project/AddDocument.vue')
      },
      {
        name: 'AddExpense',
        path: 'projects/:id/expense/new',
        component: () => import(/* webpackChunkName: "addExpense" */ '@/components/pages/project/AddExpense.vue')
      },
      {
        name: 'AddClientDocument',
        path: 'client/:id/new',
        component: () => import(/* webpackChunkName: "AddTeamMembers" */ '@/components/pages/clients/AddDocument.vue')
      },
      {
        name: 'AddSubcontratorDocument',
        path: 'studio/subcontractors/:id/document',
        component: () =>
          import(/* webpackChunkName: "AddTeamMembers" */ '@/components/pages/studio/AddSubcontractorDocument.vue')
      },
      {
        name: 'EditStudioDetails',
        path: '/studio/edit',
        component: () => import(/* webpackChunkName: "AddTeamMembers" */ '@/components/pages/studio/EditDetails.vue')
      },
      {
        name: 'EditInvoice',
        path: '/projects/:projectId/edit-invoice/:invoiceId',
        component: () => import(/* webpackChunkName: "AddTeamMembers" */ '@/components/pages/invoice/EditInvoice.vue')
      },
      {
        path: '/projects/:projectId/sow/',
        component: () => import(/* webpackChunkName: "SowPdf" */ '@/components/templates/PdfWrapper.vue'),
        redirect: {
          name: 'SowPdf'
        },
        children: [
          {
            name: 'SowPdf',
            path: '',
            component: () => import(/* webpackChunkName: "SowPdf" */ '@/components/pages/sow/SowPdf.vue')
          }
        ]
      },
      {
        path: '/projects/:projectId/sow/',
        component: () => import(/* webpackChunkName: "SowPdf" */ '@/components/templates/PdfWrapper.vue'),
        children: [
          {
            name: 'SowPdf',
            path: '',
            component: () => import(/* webpackChunkName: "SowPdf" */ '@/components/pages/sow/SowPdf.vue')
          }
        ]
      },
      {
        path: '/projects/:projectId/invoice/:invoiceId',
        component: () => import(/* webpackChunkName: "SowPdf" */ '@/components/templates/PdfWrapper.vue'),
        children: [
          {
            name: 'InvoiceDetail',
            path: 'invoice',
            component: () =>
              import(/* webpackChunkName: "InvoiceDetail" */ '@/components/pages/invoice/InvoiceDetail.vue')
          }
        ]
      },
      {
        name: 'AddRole',
        path: 'studio/roles/new',
        component: () => import(/* webpackChunkName: "service" */ '@/components/pages/studio/AddStudioRole.vue')
      },
      {
        name: 'EditRole',
        path: 'studio/roles/:id/edit',
        component: () => import(/* webpackChunkName: "service" */ '@/components/pages/studio/EditStudioRole.vue')
      },
      {
        name: 'AddProjectRoles',
        path: 'project/roles/:id/new',
        component: () => import(/* webpackChunkName: "AddTeam" */ '@/components/pages/project/AddProjectRoles.vue')
      },
      {
        name: 'EditProjectRole',
        path: 'project/roles/:id/edit/:roleId',
        component: () => import(/* webpackChunkName: "AddTeam" */ '@/components/pages/project/EditProjectRole.vue')
      }
    ]
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import(/* webpackChunkName: "Login" */ '@/components/pages/auth/Login.vue'),
    meta: {
      requiresVisitor: true
    }
  },
  {
    path: '/register',
    name: 'Register',
    component: () => import(/* webpackChunkName: "Register" */ '@/components/pages/auth/Registration.vue'),
    meta: {
      requiresVisitor: true
    }
  },
  {
    path: '/reset',
    name: 'ResetPassword',
    component: () => import(/* webpackChunkName: "Register" */ '@/components/pages/auth/ResetPassword.vue')
  },
  {
    path: '/forgot-password',
    name: 'ForgotPassword',
    component: () => import(/* webpackChunkName: "Register" */ '@/components/pages/auth/ForgotPassword.vue')
  },
  {
    name: 'VerificationMessage',
    path: '/verification/:studioName',
    component: () => import(/* webpackChunkName: "VerificationMessage" */ '@/components/pages/VerificationMessage.vue')
  },
  {
    path: '/super-admin',
    name: 'AdminWrapper',
    component: () => import('@/components/templates/SuperAdminView.vue'),
    redirect: {
      name: 'superAdminDashboard'
    },
    meta: {
      requiresSuperAdminAuth: true
    },
    children: [
      {
        name: 'superAdminDashboard',
        path: 'dashboard',
        component: () => import(/* webpackChunkName: "Dashboard" */ '@/components/pages/superAdmin/users/users.vue')
      },
      {
        name: 'analytics',
        path: 'users',
        component: () => import(/* webpackChunkName: "Dashboard" */ '@/components/pages/superAdmin/users/users.vue')
      },
      {
        name: 'payments',
        path: 'users',
        component: () => import(/* webpackChunkName: "Dashboard" */ '@/components/pages/superAdmin/users/users.vue')
      },
      {
        name: 'activity',
        path: 'users',
        component: () => import(/* webpackChunkName: "Dashboard" */ '@/components/pages/superAdmin/users/users.vue')
      },
      {
        name: 'apps',
        path: 'users',
        component: () => import(/* webpackChunkName: "Dashboard" */ '@/components/pages/superAdmin/users/users.vue')
      },
      {
        path: 'users',
        name: 'users',
        component: () => import(/* webpackChunkName: "Users" */ '../components/pages/superAdmin/users/userLayout.vue'),
        redirect: {
          name: 'ActiveUsers'
        },
        children: [
          {
            path: 'active',
            name: 'ActiveUsers',
            component: () => import(/* webpackChunkName: "Users" */ '../components/pages/superAdmin/users/users.vue')
          },
          {
            path: 'archived',
            name: 'ArchiveUsers',
            component: () => import(/* webpackChunkName: "Users" */ '../components/pages/superAdmin/users/users.vue')
          }
        ]
      },
      {
        path: 'studios',
        name: 'studios',
        component: () =>
          import(/* webpackChunkName: "Studios" */ '../components/pages/superAdmin/studios/studiosLayout.vue'),
        redirect: {
          name: 'AllStudios'
        },
        children: [
          {
            path: 'all',
            name: 'AllStudios',
            component: () =>
              import(/* webpackChunkName: "Studios" */ '../components/pages/superAdmin/studios/studios.vue')
          },
          {
            path: 'unsubscribed',
            name: 'Unsubscribed',
            component: () =>
              import(/* webpackChunkName: "Studios" */ '../components/pages/superAdmin/studios/studios.vue')
          },
          {
            path: 'pipeline',
            name: 'Pipeline',
            component: () =>
              import(/* webpackChunkName: "Studios" */ '../components/pages/superAdmin/studios/studios.vue')
          },
          {
            path: 'add',
            name: 'AddStudio',
            component: () =>
              import(/* webpackChunkName: "Studios" */ '../components/pages/superAdmin/studios/addStudio.vue')
          }
        ]
      },
      {
        path: 'studio/:studioId',
        name: 'studio',
        component: () =>
          import(/* webpackChunkName: "Studios" */ '../components/pages/superAdmin/studios/DetailsLayout.vue'),
        redirect: {
          name: 'Overview'
        },
        children: [
          {
            path: 'overview',
            name: 'Overview',
            component: () =>
              import(/* webpackChunkName: "Studios" */ '../components/pages/superAdmin/studios/studioOverview.vue')
          },
          {
            path: 'billing',
            name: 'Billing',
            component: () =>
              import(/* webpackChunkName: "Studios" */ '../components/pages/superAdmin/studios/billing.vue')
          },
          {
            path: 'adminTeam',
            name: 'AdminTeam',
            component: () => import(/* webpackChunkName: "Studios" */ '../components/pages/superAdmin/studios/team.vue')
          },
          {
            path: 'adminProjects',
            name: 'AdminProjects',
            component: () => import(/* webpackChunkName: "Studios" */ '../components/pages/project/ProjectsListing.vue')
          },
          {
            path: 'adminClients',
            name: 'AdminClients',
            component: () => import(/* webpackChunkName: "Studios" */ '../components/pages/clients/ClientListing.vue')
          },
          {
            path: 'editDetails',
            name: 'EditDetails',
            component: () =>
              import(/* webpackChunkName: "Studios" */ '../components/pages/superAdmin/studios/editStudioDetails.vue')
          },
          {
            name: 'AddAdminClient',
            path: 'clients/new',
            component: () => import(/* webpackChunkName: "AddTeam" */ '@/components/pages/clients/Client.vue')
          },
          {
            name: 'EditAdminClient',
            path: 'clients/edit/:id',
            component: () => import(/* webpackChunkName: "AddTeam" */ '@/components/pages/clients/Client.vue')
          },
          {
            name: 'ProjectAsyncWrapper',
            path: '',
            component: AsyncWrapper,
            children: [
              {
                name: 'AddAdminProject',
                path: 'projects/new',
                component: () =>
                  import(/* webpackChunkName: "EditProject" */ '@/components/pages/project/EditProject.vue')
              }
            ]
          }
        ]
      },
      {
        path: ':studioId/projects/:id',
        name: 'projectDetailsAdmin',
        component: () =>
          import(/* webpackChunkName: "ProjectDetailsLayout" */ '../components/pages/project/ProjectDetailsLayout.vue'),
        redirect: {
          name: 'ProjectDetailAdmin'
        },
        meta: {
          menuType: 'projects'
        },
        children: [
          {
            path: '',
            component: AsyncWrapper,
            children: [
              {
                path: 'details',
                name: 'ProjectDetailAdmin',
                component: () =>
                  import(/* webpackChunkName: "ProjectDetails" */ '../components/pages/project/ProjectDetails.vue')
              },
              {
                path: 'edit',
                name: 'EditProjectDetails',
                component: () =>
                  import(/* webpackChunkName: "EdityyProjectDetails" */ '../components/pages/project/EditProject.vue')
              }
            ]
          }
        ]
      },
      {
        path: ':studioId/clients/:id',
        name: 'clientDetailsAdmin',
        component: () =>
          import(/* webpackChunkName: "CLientDetailsLayout" */ '../components/pages/clients/ClientDetails.vue'),
        redirect: {
          name: 'ClientDetailAdmin'
        },
        meta: {
          menuType: 'clients'
        },
        children: [
          {
            path: '',
            component: AsyncWrapper,
            children: [
              {
                path: 'details',
                name: 'ClientDetailAdmin',
                component: () =>
                  import(/* webpackChunkName: "ClientDetails" */ '../components/pages/clients/ClientInformation.vue')
              },
              {
                path: 'edit',
                name: 'EditClientDetails',
                component: () =>
                  import(/* webpackChunkName: "EditClientDetails" */ '../components/pages/clients/Client.vue')
              }
            ]
          }
        ]
      }
    ]
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})
trackRouter(router)

router.beforeEach(async (to, from, next) => {
  if (to.meta.requiresAuth || to.meta.requiresSuperAdminAuth) {
    if (!store.state.auth.tokenContent || new Date().getTime() > store.state.auth.tokenContent.exp * 1000) {
      try {
        await store.dispatch(ActionTypes.REFRESH_TOKEN)
        if (store.state.auth.jwt.length === 0) {
          next({ name: 'Login' })
        } else if (store.state.auth.tokenContent?.role === 'SUPERADMIN') {
          next()
        } else {
          next()
        }
      } catch {
        next({ name: 'Login' })
      }
    } else {
      next()
    }
  } else if (to.matched.some((record) => record.meta.requiresVisitor)) {
    try {
      await store.dispatch(ActionTypes.REFRESH_TOKEN)
      if (store.state.auth.jwt.length !== 0) {
        next({ name: 'Studio' })
      } else {
        next()
      }
    } catch {
      next({ name: 'Login' })
    }
  } else {
    next()
  }
})

export default router
