<template>
  <ErrorMessage :errorMessage="errorMessage" v-if="showErrorMessage"></ErrorMessage>
  <Toaster :message="toasterMessage" v-if="showToasterMessage" :type="toasterType" />
  <router-view />
</template>

<script lang="ts">
import { defineComponent, computed } from 'vue'
import { useStore } from '@/store'
import { ActionTypes as AuthActionTypes } from '@/store/auth/actions'
import ErrorMessage from '@/components/atom/ErrorMessage.vue'
import Toaster from '@/components/atom/Toaster.vue'
export default defineComponent({
  name: 'App',
  components: {
    ErrorMessage,
    Toaster
  },
  setup() {
    const store = useStore()
    const isLoggedIn = computed(() => store.state.auth.jwt.length > 0)
    const showErrorMessage = computed(() => store.state.messages.showErrorMessage)
    const errorMessage = computed(() => store.state.messages.errorMessage)
    const showToasterMessage = computed(() => store.state.messages.showToasterMessage)
    const toasterMessage = computed(() => store.state.messages.toasterMessage)
    const toasterType = computed(() => store.state.messages.setToasterType)
    const logout = () => {
      store.dispatch(AuthActionTypes.LOGOUT)
    }
    return { isLoggedIn, logout, showErrorMessage, errorMessage, showToasterMessage, toasterMessage, toasterType }
  }
})
</script>
