import { mutations, Mutations } from './mutations'
import { actions, Actions, ActionTypes } from './actions'
import { Store as VuexStore, Module, CommitOptions, DispatchOptions } from 'vuex'
import type { State as RootState } from '@/store'
import type { studioTypes, subcontractorTypes } from '@shared/'

interface State {
  subContractors: subcontractorTypes.SubcontractorResponse[]
  SubcontractorDetails: subcontractorTypes.SubcontractorResponse | null
  studioDetails: studioTypes.StudioWithDetails | null
}

const state: State = {
  subContractors: [],
  SubcontractorDetails: null,
  studioDetails: null
}

const user: Module<State, RootState> = {
  state,
  mutations,
  actions
}

export { State, ActionTypes, Store }
export default user

type Store<S = State> = Omit<VuexStore<S>, 'commit' | 'getters' | 'dispatch'> & {
  commit<K extends keyof Mutations, P extends Parameters<Mutations[K]>[1]>(
    key: K,
    payload: P,
    options?: CommitOptions
  ): ReturnType<Mutations[K]>
} & {
  dispatch<K extends keyof Actions>(
    key: K,
    payload: Parameters<Actions[K]>[1],
    options?: DispatchOptions
  ): ReturnType<Actions[K]>
}
