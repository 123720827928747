import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import { store, key } from '@/store'
import VueGtag from 'vue-gtag-next'

const app = createApp(App)
app.use(store, key)
app.use(router)
if ('serviceWorker' in navigator && navigator.serviceWorker.controller) {
  navigator.serviceWorker
    .getRegistrations()
    .then(function (registrations) {
      console.log('registrations', registrations)
      for (const registration of registrations) {
        registration
          .unregister()
          .then(function () {
            return self.clients.matchAll()
          })
          .then(function (clients) {
            clients.forEach((client: any) => {
              if (client.url && 'navigate' in client) {
                client.navigate(client.url)
              }
            })
          })
      }
    })
    .catch(function (err) {
      console.log('Service Worker registration failed: ', err)
    })
}
app.use(VueGtag, {
  property: {
    id: 'G-GJCPKE028T'
  }
})
app.mount('#app')
