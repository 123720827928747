import { MutationTree } from 'vuex'
import { State } from './index'
import type { projectTypes } from '@shared/'

export enum MutationTypes {
  SET_PROJECTS = 'SET_PROJECTS',
  SET_PROJECT_DETAILS = 'SET_PROJECT_DETAILS',
  SET_PROJECT_TEAM = 'SET_PROJECT_TEAM',
  ARCHIVE_PROJECT = 'ARCHIVE_PROJECT'
}

export type Mutations<S = State> = {
  [MutationTypes.SET_PROJECTS](state: S, users: projectTypes.ProjectResponse[]): void
  [MutationTypes.SET_PROJECT_DETAILS](state: S, projectDetails: projectTypes.SingleProjectResponse): void
  [MutationTypes.SET_PROJECT_TEAM](state: S, projectTeam: projectTypes.ProjectServicesResponse): void
}

export const mutations: MutationTree<State> & Mutations = {
  [MutationTypes.SET_PROJECTS](state, users) {
    state.projects = users
  },
  [MutationTypes.SET_PROJECT_DETAILS](state, projectDetails) {
    state.projectDetails = projectDetails
  },
  [MutationTypes.SET_PROJECT_TEAM](state, projectTeam) {
    state.projectTeam = projectTeam
  },
  [MutationTypes.ARCHIVE_PROJECT](state, projectId) {
    const clientIndex = state.projects.findIndex((e: any) => e.id === projectId)
    state.projects.splice(clientIndex, 1)
  }
}
