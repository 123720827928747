
import Tabs from '@/components/atom/Tabs.vue'
import { defineComponent, computed } from 'vue'
import { useStore } from '@/store'

export default defineComponent({
  name: 'Home',
  components: {
    Tabs
  },
  setup() {
    const store = useStore()
    const studioName = computed(() => (store.state.auth.tokenContent ? store.state.auth.tokenContent.studioName : ''))
    const tabs = [
      { routerName: 'Studio-Dashboard', routerText: 'Dashboard' },
      { routerName: 'Details', routerText: 'Details' },
      { routerName: 'Team', routerText: 'Team' },
      { routerName: 'Subcontractors', routerText: 'Subcontractors' },
      { routerName: 'Documents', routerText: 'Documents' },
      { routerName: 'Roles', routerText: 'Studio roles' }
    ]
    // store.dispatch(UserActionTypes.GET_USERS)
    return { tabs, studioName }
  }
})
