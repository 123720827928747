import { AxiosError, AxiosRequestConfig } from 'axios'
import { BaseApi } from './base'
import { authTypes } from '@shared/index'
import { ActionTypes } from '@/store/messages/actions'
import { MutationTypes } from '@/store/messages/mutations'
import { store } from '@/store'
class NoAuthApi extends BaseApi {
  public constructor() {
    super(process.env.VUE_APP_API_URL || '', {})
    this.instance.interceptors.response.use(this._handleResponse, this._handleError)
    this.instance.interceptors.request.use(this._handleRequest)
  }

  public login = (loginCredentials: authTypes.AuthBody) =>
    this.instance.post<authTypes.TokenResponse>('/auth/login', loginCredentials)

  public logout = () => this.instance.post('/auth/logout')

  public register = (registerBody: authTypes.RegisterBody) =>
    this.instance.post<authTypes.TokenResponse>('/auth/register', registerBody)

  public refreshToken = () => this.instance.post<authTypes.TokenResponse>('/auth/refresh')

  public recoverPassword = (payload: authTypes.SetPassword) => this.instance.post('/auth/recover', payload)
  public resetPassword = (payload: authTypes.PasswordReset) => this.instance.post('/auth/reset', payload)

  protected _handleError = (error: AxiosError) => {
    if (error.response?.data !== 'No valid refresh token') {
      store.dispatch(MutationTypes.SET_ERROR_MESSAGE, error.response?.data)
      store.dispatch(MutationTypes.SET_LOADING, false)
      store.dispatch(ActionTypes.SHOW_ERROR_MESSAGE, true)
    }
    Promise.reject(error)
  }

  protected _handleRequest = (config: AxiosRequestConfig) => {
    store.dispatch(MutationTypes.SET_LOADING, true)
    config.withCredentials = true
    return config
  }
}

const noAuthApi: NoAuthApi = new NoAuthApi()

export default noAuthApi
