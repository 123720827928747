import { ActionContext, ActionTree } from 'vuex'
import { Mutations, MutationTypes } from './mutations'
import { State } from './index'
import type { State as RootState } from '@/store'
import authApi from '@/api/Authenticated'
import { number } from 'yup'

export enum ActionTypes {
  GET_USERS = 'GET_USERS'
}

type AugmentedActionContext = {
  commit<K extends keyof Mutations>(key: K, payload: Parameters<Mutations[K]>[1]): ReturnType<Mutations[K]>
} & Omit<ActionContext<State, RootState>, 'commit'>

export interface Actions {
  [ActionTypes.GET_USERS]({ commit }: AugmentedActionContext, id?: number): void
}

export const actions: ActionTree<State, RootState> & Actions = {
  async [ActionTypes.GET_USERS]({ commit }, id?) {
    const res = id ? await authApi.getUsers(id) : await authApi.getUsers()
    commit(MutationTypes.SET_USERS, res)
  }
}
