import { ActionContext, ActionTree } from 'vuex'
import { Mutations, MutationTypes } from './mutations'
import { State } from './index'
import type { State as RootState } from '@/store'

export enum ActionTypes {
  SET_ERROR_MESSAGE = 'SET_ERROR_MESSAGE',
  SET_LOADING = 'SET_LOADING',
  SHOW_ERROR_MESSAGE = 'SHOW_ERROR_MESSAGE',
  TIMER_FOR_MESSGE = 'TIMER_FOR_MESSGE',
  SET_TOASTER_MESSAGE = 'SET_TOASTER_MESSAGE',
  SHOW_TOASTER_MESSAGE = 'SHOW_TOASTER_MESSAGE',
  SET_TOASTER_TYPE = 'SET_TOASTER_TYPE'
}

type AugmentedActionContext = {
  commit<K extends keyof Mutations>(key: K, payload: Parameters<Mutations[K]>[1]): ReturnType<Mutations[K]>
} & Omit<ActionContext<State, RootState>, 'commit'>

export interface Actions {
  [ActionTypes.SET_ERROR_MESSAGE]({ commit }: AugmentedActionContext, errorMessage: string): void
  [ActionTypes.SET_LOADING]({ commit }: AugmentedActionContext, loading: boolean): void
  [ActionTypes.SHOW_ERROR_MESSAGE]({ commit }: AugmentedActionContext, payload: boolean): void
  [ActionTypes.TIMER_FOR_MESSGE]({ commit }: AugmentedActionContext, duration: number): void
  [ActionTypes.SET_TOASTER_MESSAGE]({ commit }: AugmentedActionContext, toasterMessage: string): void
  [ActionTypes.SHOW_TOASTER_MESSAGE]({ commit }: AugmentedActionContext, payload: boolean): void
  [ActionTypes.SET_TOASTER_TYPE]({ commit }: AugmentedActionContext, toasterType: string): void
}

export const actions: ActionTree<State, RootState> & Actions = {
  async [ActionTypes.SET_ERROR_MESSAGE]({ commit }, errorMessage) {
    commit(MutationTypes.SET_ERROR_MESSAGE, errorMessage)
  },
  async [ActionTypes.SET_LOADING]({ commit }, loading) {
    commit(MutationTypes.SET_LOADING, loading)
  },
  async [ActionTypes.SHOW_ERROR_MESSAGE]({ commit, dispatch }, payload) {
    commit(MutationTypes.SHOW_ERROR_MESSAGE, payload)
    dispatch(ActionTypes.TIMER_FOR_MESSGE, 10 * 1000)
  },
  async [ActionTypes.TIMER_FOR_MESSGE]({ commit }, duration) {
    setTimeout(() => {
      commit(MutationTypes.SHOW_ERROR_MESSAGE, false)
      commit(MutationTypes.SET_MODAL_MESSAGE_COLOR, '#E4536B')
      commit(MutationTypes.SHOW_TOASTER_MESSAGE, false)
    }, duration)
  },
  async [ActionTypes.SET_TOASTER_MESSAGE]({ commit }, toasterMessage) {
    commit(MutationTypes.SET_TOASTER_MESSAGE, toasterMessage)
  },
  async [ActionTypes.SHOW_TOASTER_MESSAGE]({ commit, dispatch }, payload) {
    commit(MutationTypes.SHOW_TOASTER_MESSAGE, payload)
    dispatch(ActionTypes.TIMER_FOR_MESSGE, 2 * 1000)
  },
  async [ActionTypes.SET_TOASTER_TYPE]({ commit }, toasterType) {
    commit(MutationTypes.SET_TOASTER_TYPE, toasterType)
  }
}
