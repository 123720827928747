import { GetterTree } from 'vuex'
import { State } from './index'
import type { State as RootState } from '@/store'
import type { clientTypes } from '@shared/'
export type Getters<S = State> = {
  activeClients(state: S): clientTypes.ClientResponse[]
  archiveClients(state: S): clientTypes.ClientResponse[]
}

export const getters: GetterTree<State, RootState> & Getters = {
  activeClients: (state) => {
    return state.clients.filter((e) => e.isArchived !== true)
  },
  archiveClients: (state) => {
    return state.clients.filter((e) => e.isArchived === true)
  }
}
