<template>
  <nav>
    <ul>
      <li v-for="link in links" :key="link.routerName">
        <router-link
          :class="$route.meta.menuType == link.menuType ? 'router-link-active' : ''"
          :to="{ name: link.routerName }"
        >
          <img :src="require('../../assets/icons/' + link.icon)" />
          {{ link.routerText }}
        </router-link>
      </li>
    </ul>
  </nav>
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue'
export default defineComponent({
  props: {
    links: {
      type: Array as PropType<{ routerName: string; routerText: string; icon: string; menuType: string }[]>,
      default: () => []
    },
    isLoggedIn: Boolean
  },
  name: 'Header'
})
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="sass">
nav
  box-shadow: inset -2px 0px 0px rgba(0, 0, 0, 0.05)
  width: 14rem
  background: $white
  z-index: 10
  ul
    list-style: none
    padding-left: 0
    margin: 0
    li
      a
        font-size: 1rem
        display: flex
        padding: 1.5rem
        line-height: 1.25rem
        color: map-get($greyShades, 'greyShade60')
        text-decoration: none
        border-right: solid transparent 4px
        &.router-link-active
          color: $primary
          font-weight: bold
          border-right: solid $purple 4px
        img
          margin-right: 1rem
          width: 1.125rem
          height: 1.125rem
  @media( max-width: $md-breakpoint)
    width: 100%
    box-shadow: inset 0px -1px 0px rgba(0, 0, 0, 0.05)
    height: 4.5rem
    padding: 0 0.25rem
    display: flex
    ul
      flex: 1
      display: flex
      align-items: center
      li
        flex: 1
        a
          @media( max-width: $md-breakpoint)
            flex-direction: column
            padding: 0
            border: none
            align-items: center
            font-size: 0.75rem
            line-height: 0.75rem
            &.router-link-active
              border: none
          img
            @media( max-width: $md-breakpoint)
              width: 1.5rem
              height: 1.5rem
              margin-right: 0
              margin-bottom: 0.5rem
</style>
