import { MutationTree } from 'vuex'
import { State } from './index'

export enum MutationTypes {
  SET_ERROR_MESSAGE = 'SET_ERROR_MESSAGE',
  SET_LOADING = 'SET_LOADING',
  SHOW_ERROR_MESSAGE = 'SHOW_ERROR_MESSAGE',
  SET_MODAL_MESSAGE_COLOR = 'SET_MODAL_MESSAGE_COLOR',
  SET_TOASTER_MESSAGE = 'SET_TOASTER_MESSAGE',
  SHOW_TOASTER_MESSAGE = 'SHOW_TOASTER_MESSAGE',
  SET_TOASTER_TYPE = 'SET_TOASTER_TYPE'
}

export type Mutations<S = State> = {
  [MutationTypes.SET_ERROR_MESSAGE](state: S, errorMessage: string): void
  [MutationTypes.SET_LOADING](state: S, loading: boolean): void
  [MutationTypes.SHOW_ERROR_MESSAGE](state: S, payload: boolean): void
  [MutationTypes.SET_MODAL_MESSAGE_COLOR](state: S, color: string): void
  [MutationTypes.SET_TOASTER_MESSAGE](state: S, toasterMessage: string): void
  [MutationTypes.SHOW_TOASTER_MESSAGE](state: S, payload: boolean): void
  [MutationTypes.SET_TOASTER_TYPE](state: S, toasterType: string): void
}

export const mutations: MutationTree<State> & Mutations = {
  [MutationTypes.SET_ERROR_MESSAGE](state, errorMessage) {
    state.errorMessage = errorMessage
  },
  [MutationTypes.SET_LOADING](state, isLoading) {
    state.isLoading = isLoading
  },
  [MutationTypes.SHOW_ERROR_MESSAGE](state, payload) {
    state.showErrorMessage = payload
  },
  [MutationTypes.SET_MODAL_MESSAGE_COLOR](state, color) {
    state.messageColor = color
  },
  [MutationTypes.SET_TOASTER_MESSAGE](state, toasterMessage) {
    state.toasterMessage = toasterMessage
  },
  [MutationTypes.SHOW_TOASTER_MESSAGE](state, payload) {
    state.showToasterMessage = payload
  },
  [MutationTypes.SET_TOASTER_TYPE](state, toasterType) {
    state.setToasterType = toasterType
  }
}
