<template>
  <button
    :type="type"
    :disabled="disabled"
    :class="[color, size]"
    @click="onClick"
    class="button"
    :data-testid="testId"
  >
    <span>{{ label }}</span>
    <div v-if="iconVisible" :class="iconClass" class="button__icon" />
  </button>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'Button',
  emits: ['onClick'],
  props: {
    color: {
      type: String,
      default: null
    },
    label: {
      type: String,
      required: true
    },
    iconVisible: {
      type: Boolean,
      default: true
    },
    disabled: {
      type: Boolean,
      default: false
    },
    size: {
      type: String,
      default: null
    },
    iconClass: {
      type: String,
      default: 'right-arrow'
    },
    type: {
      type: String,
      default: 'button'
    },
    testId: {
      type: String,
      default: 'button'
    }
  },
  setup(props, { emit }) {
    const onClick = () => {
      emit('onClick')
    }
    return { onClick }
  }
})
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="sass">
.button
  display: flex
  flex-direction: row
  justify-content: space-between
  align-items: center
  padding: 1rem 1.5rem
  width: 100%
  height: 3.25rem
  background: $primary
  color: $white
  border-radius: 3.5rem
  border: solid 1px transparent
  font-size: 16px
  cursor: pointer
  span
    color: $white
    width: max-content
  > div
    background-repeat: no-repeat
  &:focus
    outline: none
  &:disabled
    border: solid 1px map-get($greyShades, 'greyShade30')
    background-color: $background
    line-height: 1
    cursor: not-allowed
    span
      color: map-get($greyShades, 'greyShade30')
    .right-arrow
      background-image: url('~@/assets/icons/arrow-right-grey.svg')
  &__icon
    display: flex
    width: 1.5rem
    height: 1.5rem
    background-size: contain
    background-position: center
.plus
  width: 1rem
  height: 1rem
  background-image: url('~@/assets/icons/plus-black.svg')
.plus-white
  width: 1rem
  height: 1rem
  background-image: url('~@/assets/icons/plus-white.svg')
.right-arrow
  background-image: url('~@/assets/icons/arrow-right-white.svg')
.edit
  width: 1rem
  height: 1rem
  background-image: url('~@/assets/icons/edit.svg')
.primary
  background: $primary
.white
  background-color: transparent
  border-color: $primary
  color: $primary
  span
    color: $primary
  .right-arrow
    background-image: url('~@/assets/icons/arrow-right-black.svg')
  .plus
    width: 1rem
    height: 1rem
    background-image: url('~@/assets/icons/plus-black.svg')
.blue
  background-color: $purple
  border-color: transparent
  color: $white
.small
  width: unset
  padding: 0.75rem 1.125rem 0.75rem 1rem
  height: 2.625rem
  font-size: 0.875rem
  .button__icon
    margin-left: 0.75rem
    background-size: 0.75rem 0.75rem
.grey
  background-color: map-get($greyShades, 'greyShade10')
  span
    color: $primary
.darkGrey
  background-color: map-get($greyShades, 'greyShade90')
  color: map-get($greyShades, 'greyShade10')
  border-radius: unset
  padding: 6px 11px
  font-size: 14px
  font-weight: 700
  height: auto
  border-radius: 2px
  font-family: TT-Norms-Pro
  font-style: normal
  font-weight: bold
</style>
