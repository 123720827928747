
import { computed, defineComponent } from 'vue'
import { useStore } from '@/store'
import { ActionTypes } from '@/store/messages/actions'
import { MutationTypes } from '@/store/messages/mutations'
export default defineComponent({
  props: {
    errorMessage: {
      type: String,
      required: true
    }
  },
  setup() {
    const store = useStore()
    function close() {
      store.dispatch(ActionTypes.SHOW_ERROR_MESSAGE, false)
      store.commit(MutationTypes.SET_MODAL_MESSAGE_COLOR, '#E4536B')
    }
    const messageColor = computed(() => {
      return store.state.messages.messageColor
    })
    return { close, messageColor }
  }
})
