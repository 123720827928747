import { MutationTree } from 'vuex'
import { State } from './index'
import type { TokenContentClient } from './types'
import type { authTypes } from '@shared/'
export enum MutationTypes {
  SET_LOGIN = 'SET_LOGIN',
  SET_TOKEN_CONTENT = 'SET_TOKEN_CONTENT',
  SET_STUDIO_DETAILS = 'SET_STUDIO_DETAILS'
}

export type Mutations<S = State> = {
  [MutationTypes.SET_LOGIN](state: S, jwt: string): void
  [MutationTypes.SET_TOKEN_CONTENT](state: S, tokenContent: TokenContentClient): void
  [MutationTypes.SET_STUDIO_DETAILS](state: S, studioResponse: authTypes.StudioResponse): void
}

export const mutations: MutationTree<State> & Mutations = {
  [MutationTypes.SET_LOGIN](state, jwt) {
    state.jwt = jwt
  },
  [MutationTypes.SET_TOKEN_CONTENT](state, tokenContent) {
    state.tokenContent = tokenContent
  },
  [MutationTypes.SET_STUDIO_DETAILS](state, studioResponse) {
    state.studioDetails = studioResponse
  }
}
