<template>
  <div class="subnav">
    <h2>{{ studioName }}</h2>
    <Tabs :tabs="tabs" />
  </div>
  <div class="subroute">
    <router-view></router-view>
  </div>
</template>
<script lang="ts">
import Tabs from '@/components/atom/Tabs.vue'
import { defineComponent, computed } from 'vue'
import { useStore } from '@/store'

export default defineComponent({
  name: 'Home',
  components: {
    Tabs
  },
  setup() {
    const store = useStore()
    const studioName = computed(() => (store.state.auth.tokenContent ? store.state.auth.tokenContent.studioName : ''))
    const tabs = [
      { routerName: 'Studio-Dashboard', routerText: 'Dashboard' },
      { routerName: 'Details', routerText: 'Details' },
      { routerName: 'Team', routerText: 'Team' },
      { routerName: 'Subcontractors', routerText: 'Subcontractors' },
      { routerName: 'Documents', routerText: 'Documents' },
      { routerName: 'Roles', routerText: 'Studio roles' }
    ]
    // store.dispatch(UserActionTypes.GET_USERS)
    return { tabs, studioName }
  }
})
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="sass">
.subnav
  background: #fff
  padding: 0 3.75rem
  h2
    margin: 1rem 0
  @media( max-width: $md-breakpoint)
    padding: 0
    white-space: nowrap
    h2
      display: none
</style>
