<template>
  <div class="fullpage">
    <Suspense v-if="studioDetails">
      <template #default>
        <router-view />
      </template>
      <template #fallback>
        <div>Loading ...</div>
      </template>
    </Suspense>
  </div>
</template>

<script lang="ts">
import { defineComponent, computed, onMounted } from 'vue'
import { useStore } from '@/store'
import { useRoute } from 'vue-router'
import { ActionTypes as studioActions } from '@/store/studio/actions'

export default defineComponent({
  setup() {
    const store = useStore()
    const route = useRoute()
    onMounted(() => {
      if (route.path.includes('super-admin'))
        store.dispatch(studioActions.GET_STUDIO_DETAILS_BY_ID, +route.params.studioId)
      else store.dispatch(studioActions.GET_STUDIO_DETAILS_BY_ID)
    })
    const studioDetails = computed(() => store.state.studio.studioDetails)
    return { studioDetails }
  }
})
</script>
