<template>
  <div :class="{ isActive: isActive }" class="tabs">
    <router-link
      v-for="tab in tabs"
      :key="tab.routerName"
      :to="{ name: tab.routerName }"
      :class="{ active: tab.routerIcon }"
      class="tab"
    >
      {{ tab.routerText }}
      <Tooltip
        :tooltipTitle="tab.tooltipTitle"
        :tooltipText="tab.tooltipText"
        fieldType="tab"
        class="inline-flex ml-auto"
        v-if="tab.routerIcon"
      >
        <img src="@/assets/icons/question-mark.svg" @click="isActive = !isActive" />
      </Tooltip>
    </router-link>
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType, ref } from 'vue'
import Tooltip from '@/components/atom/Tooltip.vue'
export default defineComponent({
  name: 'Tabs',
  components: {
    Tooltip
  },
  props: {
    tabs: {
      type: Array as PropType<
        { routerName: string; routerText: string; routerIcon?: string; tooltipText?: string; tooltipTitle?: string }[]
      >,
      default: () => [],
      required: true
    }
  },
  setup() {
    const isActive = ref(false)
    return { isActive }
  }
})
</script>

<style lang="sass" scoped>
.tabs
  flex: none
  height: 3.25rem
  display: flex
  // overflow-x: auto
  padding: 0
  -ms-overflow-style: none
  scrollbar-width: none
  &::-webkit-scrollbar
    display: none
  .tab
    margin-right: 1rem
    border-bottom: solid 2px transparent
    transition: all .2s
    text-decoration: none
    color: $primary
    line-height: 3.25rem
    img
      margin: 0px 0px -3px 5px
      max-width: 15px
      max-height: 15px
    &:active,&:focus
      outline: none
    &.router-link-exact-active
      font-weight: bold
      border-bottom: solid 2px $purple
  @media( max-width: $md-breakpoint)
    box-shadow: inset 0px -1px 0px rgba(0, 0, 0, 0.05)
    margin: 0 1.5rem
    overflow-x: auto
    &.isActive
      overflow: unset
</style>
