
import { defineComponent, PropType, ref } from 'vue'
import Tooltip from '@/components/atom/Tooltip.vue'
export default defineComponent({
  name: 'Tabs',
  components: {
    Tooltip
  },
  props: {
    tabs: {
      type: Array as PropType<
        { routerName: string; routerText: string; routerIcon?: string; tooltipText?: string; tooltipTitle?: string }[]
      >,
      default: () => [],
      required: true
    }
  },
  setup() {
    const isActive = ref(false)
    return { isActive }
  }
})
