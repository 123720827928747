<template>
  <span class="toolTipWrap">
    <slot />
    <div :class="checkMediaQuery(fieldType)" class="toolTipInner mobile-input" v-if="tooltipText">
      <div>
        <b>{{ tooltipTitle }}</b>
      </div>
      <div>{{ tooltipText }}</div>
    </div>
  </span>
</template>

<script lang="ts">
export default {
  name: 'Tooltip',
  props: {
    tooltipTitle: {
      type: String,
      default: ''
    },
    tooltipText: {
      type: String,
      default: ''
    },
    position: {
      default: 'right',
      type: String
    },
    fieldType: {
      type: String,
      default: ''
    }
  },
  setup() {
    const checkMediaQuery = (fieldType: string) => {
      if (window.innerWidth <= 768) {
        return fieldType === 'input'
          ? 'top'
          : fieldType === 'dashboard' || fieldType === 'growth'
          ? 'left'
          : fieldType === 'timesheet'
          ? 'top'
          : 'bottom'
      }
      return fieldType === 'growth' ? 'left' : fieldType === 'timesheet' ? 'top' : 'right'
    }
    return { checkMediaQuery }
  }
}
</script>
<style scoped lang="scss">
$gray: #0e1e36;
.toolTipWrap {
  display: inline-block;
  position: relative;
  z-index: 9;
  .toolTipInner {
    position: absolute;
    width: max-content;
    z-index: 99;
    background: $gray;
    color: lightyellow;
    padding: 15px;
    opacity: 0;
    max-width: 278px;
    text-align: justify;
    pointer-events: none;
    font-size: 14px;
    div:first-child {
      font-size: 16px;
    }

    &:before {
      position: absolute;
      content: '';
      border: 8px solid transparent;
      display: block;
      width: 0;
      height: 0;
    }
    &.right,
    &.left {
      top: 50%;
      transform: translateY(-50%);
      &:before {
        top: 50%;
        transform: translateY(-50%);
      }
    }
    &.top,
    &.bottom {
      left: 50%;
      transform: translateX(-50%);
      &:before {
        left: 50%;
        transform: translateX(-8px);
      }
    }
    &.right {
      left: calc(100% + 15px);
      &:before {
        border-left: 0;
        border-right-color: $gray;
        right: 100%;
      }
    }
    &.left {
      right: calc(100% + 15px);
      &:before {
        border-right: 0;
        border-left-color: $gray;
        left: 100%;
      }
    }
    &.bottom {
      top: calc(100% + 15px);
      &:before {
        border-top: 0;
        border-bottom-color: $gray;
        bottom: 100%;
      }
    }
    &.top {
      bottom: calc(100% + 15px);
      &:before {
        border-bottom: 0;
        border-top-color: $gray;
        top: 100%;
      }
    }
    @media screen and (max-width: $ms-breakpoint) {
      &.mobile-dashboard {
        right: calc(100% + 15px);
        &:before {
          border-right: 0;
          border-left-color: $gray;
          left: 100%;
        }
      }
    }
  }
  &:hover {
    .toolTipInner {
      pointer-events: all;

      &.right,
      &.left {
        animation: tooltips-horz 300ms ease-out forwards;
      }

      &.top,
      &.bottom {
        animation: tooltips-vert 300ms ease-out forwards;
      }
    }
  }
}
/* KEYFRAMES */
@keyframes tooltips-vert {
  to {
    opacity: 0.9;
    transform: translate(-50%, 0);
  }
}

@keyframes tooltips-horz {
  to {
    opacity: 0.9;
    transform: translate(0, -50%);
  }
}
</style>
<style lang="sass">
.tabs
  .tab
    &.active
      .toolTipInner
        line-height: 2
</style>
