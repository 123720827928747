import { MutationTree } from 'vuex'
import { State } from './index'
import type { clientTypes } from '@shared/'

export enum MutationTypes {
  SET_CLIENTS = 'SET_CLIENTS',
  SET_CLIENT_DETAILS = 'SET_CLIENT_DETAILS',
  DELETE_CLIENT = 'DELETE_CLIENT'
}

export type Mutations<S = State> = {
  [MutationTypes.SET_CLIENTS](state: S, users: clientTypes.ClientResponse[]): void
  [MutationTypes.SET_CLIENT_DETAILS](state: S, clientDetails: clientTypes.ClientResponse): void
  [MutationTypes.DELETE_CLIENT](state: S, clientId: number): void
}

export const mutations: MutationTree<State> & Mutations = {
  [MutationTypes.SET_CLIENTS](state, users) {
    state.clients = users
  },
  [MutationTypes.SET_CLIENT_DETAILS](state, clientDetails) {
    state.clientDetails = clientDetails
  },
  [MutationTypes.DELETE_CLIENT](state, clientId) {
    const clientIndex = state.clients.findIndex((e: any) => e.id === clientId)
    state.clients.splice(clientIndex, 1)
  }
}
