<template>
  <div id="myModal" class="modal" data-testid="modal-error">
    <!-- Modal content -->
    <div :style="{ backgroundColor: messageColor }" class="modal-content">
      <p>{{ errorMessage }}</p>
      <div @click="close" class="close-wrapper">
        <span :style="{ color: messageColor }" class="close">&times;</span>
      </div>
    </div>
  </div>
</template>
<script lang="ts">
import { computed, defineComponent } from 'vue'
import { useStore } from '@/store'
import { ActionTypes } from '@/store/messages/actions'
import { MutationTypes } from '@/store/messages/mutations'
export default defineComponent({
  props: {
    errorMessage: {
      type: String,
      required: true
    }
  },
  setup() {
    const store = useStore()
    function close() {
      store.dispatch(ActionTypes.SHOW_ERROR_MESSAGE, false)
      store.commit(MutationTypes.SET_MODAL_MESSAGE_COLOR, '#E4536B')
    }
    const messageColor = computed(() => {
      return store.state.messages.messageColor
    })
    return { close, messageColor }
  }
})
</script>
<style lang="sass" scoped>
.modal
  position: fixed
  z-index: 1
  left: 0
  top: 0
  width: 100%
  height: 100%
  overflow: auto
  background-color: rgba(0,0,0,0.4)

/* Modal Content/Box */
.modal-content
  margin: 7% auto
  padding: 20px
  width: 30%
  border-radius: 4px
  display: flex
  justify-content: space-between
  align-items: center
  p
    color: $white
    font-size: 16px
    font-weight: 700

/* The Close Button */
.close
  font-size: 28px
  font-weight: bold
.close-wrapper
  float: right
  padding: 1rem
  width: fit-content
  height: 2rem
  width: 2rem
  display: flex
  align-items: center
  justify-content: center
  border-radius: 100%
  background-color: $white
  cursor: pointer

.close:hover,
.close:focus
  text-decoration: none
  cursor: pointer
</style>
