import { MutationTree } from 'vuex'
import { State } from './index'
import type { userTypes } from '@shared/'

export enum MutationTypes {
  SET_USERS = 'SET_USERS',
  USER_CREATED = 'USER_CREATED'
}

export type Mutations<S = State> = {
  [MutationTypes.SET_USERS](state: S, users: userTypes.UserResponse[]): void
  [MutationTypes.USER_CREATED](state: S, user: userTypes.UserResponse): void
}

export const mutations: MutationTree<State> & Mutations = {
  [MutationTypes.SET_USERS](state, users) {
    state.users = users
  },
  [MutationTypes.USER_CREATED](state, user) {
    state.users.push(user)
  }
}
