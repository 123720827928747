
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'Button',
  emits: ['onClick'],
  props: {
    color: {
      type: String,
      default: null
    },
    label: {
      type: String,
      required: true
    },
    iconVisible: {
      type: Boolean,
      default: true
    },
    disabled: {
      type: Boolean,
      default: false
    },
    size: {
      type: String,
      default: null
    },
    iconClass: {
      type: String,
      default: 'right-arrow'
    },
    type: {
      type: String,
      default: 'button'
    },
    testId: {
      type: String,
      default: 'button'
    }
  },
  setup(props, { emit }) {
    const onClick = () => {
      emit('onClick')
    }
    return { onClick }
  }
})
